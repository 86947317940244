/*********************************************/
/*          CUSTOMIZED DEFAULTS         */
/*********************************************/


/* 
041B2D
FF9CDA
EA4492
004E9A
*/



@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@200;300;400;500;600;700&family=Hind:wght@200;300;400;500;600;700&family=Kalnia:wght@400;500;600&display=swap');
@import "~@flaticon/flaticon-uicons/css/all/all";
@import "./assets/lineawesome/css/line-awesome.min.css";


@font-face {
  font-family: Gilroy;
  src: url("assets/gilroy/Gilroy-ExtraBold.otf");
  font-weight: 600;
}


@font-face {
  font-family: Gilroy;
  src: url("assets/gilroy/Gilroy-Light.otf");
  font-weight: 300;
}


@font-face {
  font-family: Myriad;
  src: url("assets/Myriad/MyriadPro-Light.otf");
  font-weight: 100;
}


@font-face {
  font-family: Myriad;
  src: url("assets/Myriad/MYRIADPRO-REGULAR.OTF");
  font-weight: 300;
}

@font-face {
  font-family: Myriad;
  src: url("assets/Myriad/MYRIADPRO-SEMIBOLD.OTF");
  font-weight: 500;
}

@font-face {
  font-family: Myriad;
  src: url("assets/Myriad/MYRIADPRO-BOLD.OTF");
  font-weight: 600;
}

$color-white: #FFFFFF;
$color-light: #FAFAFA;
$color-grey: #888888;
$color-brown: #B18D5B;
$color-black: #000000;


$font-family-base: "Hind", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Lato", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$font-family-sans-serif: "Hind",  sans-serif;
$font-family-weight: 300;

// color: #4747FE!important;
$btn-font-family: "Hind";
$btn-font-weight: 500;

$enable-rounded: true;
$enable-shadows: true;
$enable-gradients: true;


$body-color: #484978; // The default text color
$headings-color: #000000;

$primary:    #4747FE; //rgb(26,115,232); //#5291F0; 
$secondary:  rgb(241,38,57); //#FF616F;
$success:    #198754;
$info:       #E4E4FC;
$warning:    #ffc107;
$danger:     #dc3545;
$black:       #0C2A4E;
$dark:       #343340; //#514fd4;
$light:      #F5F5FC; //#F9EBD3

$gray: #E4E4FC!important; 
$lightgrey: #F5F5FC;


$body-bg: $light;
$body-color: #50517D;
$list-group-bg: #fff;
$nav-tabs-link-active-bg: #fff;
$form-valid-border-color: rgba($color: $success, $alpha: 0.2);
$form-invalid-border-color: rgba($color: $danger, $alpha: 0.2);

$heading-color: $danger;
$light-background-color: #F9EBD3;
$headings-font-family: "Outfit";
$headings-font-weight: 400;
$headings-line-height: 1.2;
// $headings-font-color:  #4747FE;


$list-group-active-bg: $light;
$list-group-active-color: $color-black;
$card-cap-bg: #fff;
$card-border-color: rgba(55,125,255,0.1);
$list-group-border-color: rgba(55,125,255,0.1);
$link-color: rgb(33,50,91); //#2c7be5;
$nav-link-color: rgb(33,50,91); 
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1320px
);

// 0 0.5rem 1rem rgba(33, 50, 91, 0.15);
$box-shadow-sm: 0 0rem 0rem rgba(33, 50, 91, 0.15);

html {
  scroll-behavior: smooth;
}
/* Padding below the footer and lighter body text */

body {
  margin: 0;
  font-weight: 300!important;
  font-size: 0.85rem!important;
  -webkit-font-smoothing: antialiased!important;
  // F7F7FE
  // background-color: #F5F8FD!important; //#F8FAFF!important;
  background: linear-gradient(90deg,$light,$light)!important;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  // color: #A1A1A1!important;
  // color: #5e6e82!important;
}

.highlight {
  background-image: -webkit-linear-gradient($dark, $primary);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 800!important;

}


.card.selected {
  // color: $dark!important;
  background-color: $info!important;
}

.card.selected b, .card.selected small {
  // color: #fff!important;
}

.card-footer {
  background-color: #fff!important;
}
p.small {
  line-height: 1rem;
}

.text-muted {
    color: #5e6e82!important;
}
b, strong {
  font-weight: 600!important;
  font-family: $headings-font-family;
}

.shadow-sm, .card {
  box-shadow: none!important;
}

.card {
  background-color: #fff!important;;
  border-color: $info!important;
}

h1, h2 {
  // font-family: "Kalnia"!important;
  font-weight: 500!important;
}

.bg-gray {
  background-color: #FCFDFF!important;
}

.btn {
  font-size: 1em!important;
}

.btn-dark {
  background: $dark!important;
  color: #fff!important;
  border: 0px!important;
}

.btn-primary {
  background: $primary!important;
  color: #fff!important;
  border: 0px!important;
}

.btn-secondary {
  background: $secondary!important;
  color: #fff!important;
  border: 0px!important;
}

.btn .fi {
  float: inline-start;
  margin: 0px;
  padding: 0px;
  margin-top: 4px;
}

blockquote {
  background-color: $light;
  padding: 5px 5px;
  border-radius: 0.3rem;
}

.nav-link.btn {
  font-family: "Outfit"!important;
}

.nav-link {
  font-family: "Outfit"!important;
}
.nav-link.active {
  font-weight: bold;
  color: $primary!important;
}

.nav-pill .nav-link.active {
  background-color: #fff!important;
  border-radius: 0.5rem;
  text-transform: capitalize;
}

.dropdown-item, .form-control, select {
  font-weight: 300!important;
  font-size: 0.85em!important;
}

.modal .modal-xl {
  margin-left: 260px!important;
  margin-right: 10px!important;
}

.dropdown-toggle::after {
  display: none!important;
}

.form-control, select {
  font-weight: 300!important;
  font-size: 1em!important;
  background-color: #fff!important;
}
.nav-pills .nav-link, .nav-underline .nav-link {
  color: $black!important;
  opacity: 0.5;
}
.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
  color: white!important;
  background-color: $dark!important;
  background-image: none!important;
  border-radius: 2rem;
  opacity: 1;

}

.nav-underline .nav-link.active, .nav-underline .show > .nav-link, .nav-link.active {
  color: $primary!important;
  opacity: 1;
}


#sidebar .nav-link {
  padding: 0px 0px!important;
  margin-bottom: 10px;
}

#sidebar .nav-link.active {
  border-right: 3px solid $primary;
  // padding: 5px 0px!important;
  // background-color: #F6F6FF;
}

body.close #sidebar .nav-link {
  padding: 10px 0px!important;
  margin-bottom: 0px!important;
}
body.close #sidebar .nav-link.active {
  border-right: 0px solid $primary!important;
  background-color: $primary;
  color: #fff!important;
}

#sidebar .nav-link.active span {
  color: $primary!important;
}

.feature {
  // background:rgba($color: $dark, $alpha: 0);
  background-color: transparent;
  padding: 60px 40px;
  border-radius:30px;
  // box-shadow: 0 10px 40px -10px rgba(0,64,128,.2);
  position: relative;
  z-index: 1;


}

.feature .callout-icon {
  position: absolute;
  left: 50%;
  top: -40px;
  margin-left: -50px;
  width: 100px;
  height: 100px;
}

.feature .feature-inner:before{
  content: "";
  background-color: #fff;
  width: 100%;
  height: 100%;
  border-radius: 100px 18px 100px 18px;
  border: 1px solid #eee;
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: -1;
}

.pricing-badge {
  position: absolute;
  top: 0;
  z-index: 999;
  right: 0;
  width: 100%;
  display: block;
  font-size: 15px;
  padding: 0;
  overflow: hidden;
  height: 100px;
}

.pricing-badge .badge {
  float: right;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  right: -67px;
  top: 17px;
  position: relative;
  text-align: center;
  width: 200px;
  font-size: 13px;
  margin: 0;
  padding: 7px 10px;
  font-weight: 500;
  color: #ffffff;
  background: $secondary;
}

.timeline {
  list-style-type: none;
  position: relative
}

.timeline:before {
  background: #dee2e6;
  left: 9px;
  width: 2px;
  height: 100%
}

.timeline-item:before,
.timeline:before {
  content: " ";
  display: inline-block;
  position: absolute;
  z-index: 1
}

.timeline-item:before {
  background: #fff;
  border-radius: 50%;
  border: 3px solid #3b7ddd;
  left: 0;
  width: 20px;
  height: 20px
}


.list-group-item.active {
  background-color: $info!important;
}


.card-header {
  // background-color: $info!important;
  border-bottom: 0px!important;
  padding: 10px 20px!important;
}
.bg-grey-gradient {
  // background-image: linear-gradient(to right,rgba(55,125,255,.03),rgba(9,165,190,.05) 50%,rgba(245,202,153,.05));
  background-color: #F8FAFF;
}


.node-label p {
  line-height: 1.2em;
}

a { 
  text-decoration: none!important;
}

.form-floating > label {
  color: $black!important;
}

@import "../node_modules/bootstrap/scss/bootstrap.scss";
// @import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600&display=swap");