.hidden { 
  display: none!important;
}

.row.display-flex {
  display: flex;
  flex-wrap: wrap;
  
}
.row.display-flex > [class*='col-'] {
  display: flex;
  flex-direction: column;
}

.highlighted-column {
  background-color: lightblue;
}

.fw-bolder {
  font-weight: 600!important;
}
.link {
  cursor: pointer;
}

#topnav {
  /* display: none; */
}


.feed-activity-list .feed-element {
  border-bottom: 1px solid #e7eaec;
}
.feed-element:first-child {
  margin-top: 0;
}
.feed-element {
  padding: 5px 15px;
}
.feed-element,
.feed-element .media {
  margin-top: 15px;
}
.feed-element,
.media-body {
  overflow: hidden;
}
.feed-element > .pull-left {
  margin-right: 10px;
}
.feed-element img.img-circle,
.dropdown-messages-box img.img-circle {
  width: 38px;
  height: 38px;
}
.feed-element .well {
  border: 1px solid #e7eaec;
  box-shadow: none;
  margin-top: 10px;
  margin-bottom: 5px;
  padding: 10px 20px;
  font-size: 11px;
  line-height: 16px;
}
.feed-element .actions {
  margin-top: 10px;
}
.feed-element .photos {
  margin: 10px 0;
}
.feed-photo {
  max-height: 180px;
  border-radius: 4px;
  overflow: hidden;
  margin-right: 10px;
  margin-bottom: 10px;
}

.section-bg {
  /* background-image: url("assets/banner.avif");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  filter: blur(6px);
  -webkit-filter: blur(6px);
  -moz-filter: blur(6px);
-o-filter: blur(6px);
-ms-filter: blur(6px);
position: absolute;
left: 0px;
top: 0px;
right: 0px;
bottom: 0px;
height: auto;
opacity: 0.1; */

}



.section-bg-middle {
  /* background-image: url("https://images.unsplash.com/photo-1527192491265-7e15c55b1ed2?q=80&w=3540&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"); */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  filter: blur(6px);
  /* filter: blur(6px);
  -webkit-filter: blur(6px);
  -moz-filter: blur(6px);
-o-filter: blur(6px);
-ms-filter: blur(6px); */
position: absolute;
left: 0px;
top: 0px;
right: 0px;
height: auto;
bottom: 0px;
/* height: 500px; */
opacity: 0.2;
overflow: hidden;

}


.section-bg-top {
  z-index: 0;
  background-image: url("https://images.unsplash.com/photo-1446716336919-df838e44ce7b?q=80&w=2409&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  filter: blur(1px);
  /* filter: blur(6px);
  -webkit-filter: blur(6px);
  -moz-filter: blur(6px);
-o-filter: blur(6px);
-ms-filter: blur(6px); */
position: absolute;
left: 0px;
top: 0px;
right: 0px;
height: auto;
bottom: 0px;
/* height: 500px; */
opacity: 0.5;
overflow: hidden;

}


.bg-image {
  z-index: -1;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  filter: blur(48px);
  -webkit-filter: blur(48px);
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  opacity: .8;
  /* background-image: url("assets/top_bg.avif"); */
  /* background-image: url("https://nftjugaad.app/static/media/randomshape.23603894.png"); */
}

.bg-white-soft {
  background: hsla(0,0%,100%,.4)!important;
  color: #000!important;
}

#header {
  width: 100%;
  height: 50px;
  margin: 50px 0;
  text-align: center;
  font-size: 28px;
  position: relative;
  /* background-color: #57585C; */
}

#header:after {
  content: '';
  width: 100%;
  border-bottom: solid 1px #57585C;
  position: absolute;
  left: 0;
  top: 50%;
  z-index: 1;
}

#header h3 {
  background-color: #FFF;
  width: auto;
  display: inline-block;
  z-index: 3;
  padding: 0 20px 0 20px;
  /* color: white; */
  position: relative;
  /* font-family: calibri; */
  font-weight: lighter;
  margin: 0;
}



/* 
#dsection:nth-child(1) {
  margin-top: -90px;
  background: linear-gradient(90deg,#EEF1F4,#F5F8FD)!important;
  clip-path: polygon(0 20%, 100% 0, 100% 80%, 0 100%);
  padding: 10rem 0% 10rem 0%;
}

#dsection.reverse:nth-child(1) {
  margin-top: -90px;
  background: linear-gradient(90deg,#EEF1F4,#F5F8FD)!important;
  clip-path: polygon(0 20%, 100% 0, 100% 80%, 0 100%);
  padding: 10rem 0% 10rem 0%;
} */

.content-container {
  /* transition: transform 0.3s ease; */
}

.offcanvas-open {
  /* transform: translateX(350px);  */
  
}


.custom-file-upload {
  display: block;
  position: relative;
  overflow: hidden;
  margin-right: 10px;
  cursor: pointer;
  
}

.custom-file-upload input[type="file"] {
  position: absolute;
  font-size: 100px;
  right: 0;
  top: 0;
  opacity: 0;
  cursor: pointer;
}

.upload-icon {
  background-color: #3498db;
  color: #ffffff;
  padding: 8px 12px;
  border-radius: 100%;
  cursor: pointer;
  
}

/* Style the icon (you can use your own upload icon) */
.upload-icon::before {
  content: '\f093'; /* Unicode for a typical upload icon (you can use FontAwesome or other icon libraries) */
  font-family: FontAwesome;
  margin-right: 5px;
}

/* .sidebar {
  width: 0;
  height: 100%;
  position: fixed;
  top: 0;
  left: -300px;
  background-color: #fff;
  transition: left 0.3s ease-in-out;
  z-index: 1;
}

.sidebar.open {
  left: 250px; 
  width: 300px;
  padding: 5px 10px;
} */

/* .close-button {
  position: absolute;
  top: 20px;
  left: 10px;
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 20px;
} */

/* #sidebar.show {
  left: 0; 
} */

.vh-100-adjusted {
  height: calc(100vh - 150px)!important
}

/* Custom styles for the page content */
body #content.open {
  margin-left: 250px!important;
  /* margin-top: 30px; */
  /* margin-bottom: 30px; */
  /* margin-right: 30px;  */
  /* background: #fff!important; */
  /* border-radius: 1rem; */
  /* height: calc(100vh - 60px)!important; */
  overflow: scroll;
  /* box-shadow: 0px 0px 0.5rem #ddd; */
}

body.close #content.open {
  margin-left: 50px!important; 
}



.offcanvas {
  /* margin: 30px 30px; */
  /* border-radius: 0.5rem; */
}
.offcanvas.show {
   /* margin-left: 250px; */
  z-index: 999999!important;
}

/* #content-sidebar {
  position: fixed;
    left: 250px;
    width: 250px;
    padding: 0px 10px;
    bottom: 0px;
    top: 0px;
    overflow: auto;
}
 */
 body #content {
  transition: margin-left 0.1s;
 }

body #sidebar {
  position: fixed;
  top: 0px;
  left: 0px; 
  width: 250px;
  bottom: 0px;
  overflow: hidden;
  /* background-color: #fff; */
  padding: 0px;
  z-index: 0;  
  /* margin-top: 50px;
  margin-bottom: 50px;
  margin-left: 20px;
  border-radius: 0.5rem; */
  padding: 20px 0px;
  background-color: #fff!important;
  height: calc(100vh)!important;
  border: 0px!important;

  -webkit-transition: width 0.1s ease-in-out;
  -moz-transition: width 0.1s ease-in-out;
  -o-transition: width 0.1s ease-in-out;
  transition: width 0.1s ease-in-out;


}

body.close #sidebar .profile-card, body.close #sidebar .support-card{
  display: none;
}


body #sidebar .profile-card-small, body #sidebar .support-card-small{
  display: none;
}

body.close #sidebar .profile-card-small, body.close #sidebar .support-card-small{
  display: block;
  margin-bottom: 10px;
}

body.close #sidebar {
  padding: 20px 0px!important;
}


body #sidebar .nav-link {
  padding-left: 30px!important;
  /* margin-bottom: 10px; */
  font-size: 1.1em;
}

body.close #sidebar .nav-link {
  padding-left: 18px!important;
}

body.close #sidebar .nav-link span {
  display: none;
}

body.close #sidebar {
  /* margin-left: -350px; */
  width: 50px;

}

body.modal-open #sidebar, body.close #sidebar {
  /* z-index: -1; */
}
  
.floating_button {
    /* position: fixed; */
    float: right;
    bottom: 20px;
    right: 20px;
    border-radius: 100%;
    width: 50px;
    height: 50px;
    overflow: hidden;
    font-size: 30px!important;
    text-align: center;
    padding: 0px;
    line-height: 55px;
    /* background-color: rgb(241,38,57)!important; */
}
#sidebar_menu_icon {
  z-index: 999999;
  /* position: fixed;
  width: 30px;
  left: 10px;
  right: 10px;
  top: 10px; */
  scale: 0.9;
  margin-top: 10px;
  margin-left: 20px;
  /* left: 10px; */
}


/* body.close #sidebar_menu_icon {
  left: -5px;
  transition: position 0.3s;
} */

#content.open #footer {
  display: none;
}




.photo-grid {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: -20px; /* Adjust the gap to control the overlapping */
}

.img-square {
  position: relative;
  z-index: 1;
}

.img-square img {
  width: 200px; /* Set the width of your images */
  height: auto; /* Maintain the aspect ratio */
  border: 2px solid #fff; /* Optional: Add a border around the images */
}

/* Adjust the positioning of the images as needed */
.img-square:nth-child(1) {
  left: 0;
}

.img-square:nth-child(2) {
  left: -20px;
}

.img-square:nth-child(3) {
  left: -40px;
}

.form-floating {
  
}
.form-floating .form-control {
  
  /* border: 1px; */
  /* border-radius: 2rem; */
  /* padding: 0px 0px!important;
  
  padding-left: 2rem; */
  /* font-weight: bold!important; */
  /* background: #F5F5FC!important; */
  /* font-size: 0.95em!important; */
}

.form-floating .form-label span {
  /* background-color: #E4E4FC!important; */
  /* padding-top: 20px;
  padding-left: 2.2rem;
  margin-bottom: 0px!important;
  padding-bottom: 0px!important; */
}


.vertical-stack {
  flex-direction: row;
}

@media (max-width: 1500px) {
  .vertical-stack {
    flex-direction: column!important;
  }
}

        /* Media query for responsive layout */
        @media (max-width: 767px) {
          
          /* #sidebar_menu_icon {
            display: none!important;
          } */
          #sidebar {
              z-index: 9999;
              /* width: 50px!important; */
              width: 100%;
              height: 100%;
          }

          #content {
              margin-left: 0; 
          }
          #content.open {
            margin-left: 0; 
        }
      }



body {
  /* overflow: hidden; */
}
